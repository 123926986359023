import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSocketIoMiddleware from 'redux-socket.io';
import createRootReducer from './store/reducers'
import { io } from 'socket.io-client';
import _ from 'lodash';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'

import App from './App';

import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga'
import rootSaga, { logger } from './sagas';

import * as websocketTypes from './store/websocket/actionTypes';

// TODO: pass in correct socket.io location from server
const clientConfig = window.client_config;
let webSocketURL = `${document.location.protocol}//${document.location.host}`;
if (!_.isEmpty(clientConfig)) {
  webSocketURL = `${clientConfig.web_protocol}://${clientConfig.web_domain}:${clientConfig.web_port}`;
}

const socket = io(webSocketURL);
const socketIoMiddleware = createSocketIoMiddleware(socket, 'server/');

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['error', 'device'],
}

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, createRootReducer(history))


const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(
    sagaMiddleware,
    logger,
    socketIoMiddleware
  )
));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
socket.on('connect_timeout', () => store.dispatch({ type: websocketTypes.WEBSOCKET_DISCONNECTED }));
socket.on('connect_error', () => store.dispatch({ type: websocketTypes.WEBSOCKET_DISCONNECTED }));
socket.on('connect', () => store.dispatch({ type: websocketTypes.WEBSOCKET_CONNECTED }));

ReactDOM.hydrate(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('root')
);
