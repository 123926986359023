import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import asyncComponent from '../components/AsyncComponent';
import * as userTypes from '../store/user/actionTypes';
import * as userSelectors from '../store/user/reducer';

const AsyncNavigation = asyncComponent(() => import('../components/Navigation'));
const AsyncHomeView = asyncComponent(() => import('../components/HomeView'));
const AsyncShortlistView = asyncComponent(() => import('./ShortlistView'));
const AsyncUserSettings = asyncComponent(() => import('../components/UserSettings'));
const AsyncDeviceContainer = asyncComponent(() => import('./DeviceContainer'));
const AsyncSiteVisualizationContainer = asyncComponent(() => import('./SiteVisualizationContainer'));
const AsyncDeviceDetails = asyncComponent(() => import('../containers/DeviceDetailsContainer'));
const AsyncDevicePublishContainer = asyncComponent(() => import('./DevicePublishContainer'));
const AsyncStatisticPanel = asyncComponent(() => import('../containers/StatisticPanel'));
const AsyncBulkUpdateTool = asyncComponent(() => import('./BulkUpdate'));
const AsyncGlobalBroadcast = asyncComponent(() => import('./GlobalBroadcast'));


const DashboardContainer = ({ location }) => {

  const user = useSelector(userSelectors.getCurrentUser);
  const dispatch = useDispatch();
  const handleLogout = () => dispatch({
      type: userTypes.USER_LOGOUT_GOOGLE_REQUESTED,
    });
  

  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <AsyncNavigation user={user} handleLogout={handleLogout} />
        </Grid.Column>
        <Grid.Column className="main-container" stretched>
          <AsyncStatisticPanel />
          <Route
            exact
            path="/home"
            component={AsyncHomeView}
          />
          <Route
            exact
            path="/shortlist"
            component={AsyncShortlistView}
          />
          <TransitionGroup>
            <CSSTransition key={location.key} classNames="fade" timeout={1000}>
              <div>
                <Switch location={location}>
                  <Route
                    path="/devices/alert"
                    render={() => <AsyncDeviceContainer location={location} alertState="ALERT" />}
                  />
                  <Route
                    path="/devices/ok"
                    render={() => <AsyncDeviceContainer location={location} alertState="OK" />}
                  />
                  <Route
                    path="/devices/inprogress"
                    render={() => <AsyncDeviceContainer location={location} alertState="IN_PROGRESS" />}
                  />
                  <Route
                    path="/devices/:deviceMac"
                    component={AsyncDeviceDetails}
                  />
                </Switch>
              </div>
            </CSSTransition>
          </TransitionGroup>
          <Route
            exact
            path="/sites"
            component={AsyncSiteVisualizationContainer}
          />
          <Route
            exact
            path="/tools/publish"
            component={AsyncDevicePublishContainer}
          />
          <Route
            exact
            path="/tools/bulk-update"
            component={AsyncBulkUpdateTool}
          />
          <Route
            exact
            path="/tools/broadcast"
            component={AsyncGlobalBroadcast}
          />
          <Route
            exact
            path="/settings"
            component={AsyncUserSettings}
          /> 
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default DashboardContainer;