export const SITES_FETCH_REQUESTED = 'SITES_FETCH_REQUESTED';
export const SITES_FETCH_SUCCEED = 'SITES_FETCH_SUCCEED';
export const SITES_FETCH_FAILED = 'SITES_FETCH_FAILED';
export const SITE_FETCH_REQUESTED = 'SITE_FETCH_REQUESTED';
export const SITE_FETCH_SUCCEED = 'SITE_FETCH_SUCCEED';
export const SITE_FETCH_FAILED = 'SITE_FETCH_FAILED';

export const SITE_DEVICES_FETCH_REQUESTED = 'SITE_DEVICES_FETCH_REQUESTED';

export const SET_SELECTED_SITE = 'SET_SELECTED_SITE';

export const WEBSOCKET_SITE_UPDATED = 'WEBSOCKET_SITE_UPDATED';
