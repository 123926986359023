import _ from 'lodash';

import * as types from './actionTypes';

const initialState = {
  isConnected: false,
  subscriptions: [],
};

export default function reduce(state = initialState, action = {}) {
  if (_.includes(action.type, 'server/')) {
    const index = _.findIndex(state.subscriptions, s => s.topic === action.type);
    if (index === -1) {
      return ({
        ...state,
        subscriptions: [
          ...state.subscriptions,
          { topic: action.type, filter: action.filter },
        ],
      });
    }
    return ({
      ...state,
      subscriptions: [
        ..._.slice(state.subscriptions, 0, index),
        { topic: action.type, filter: action.filter },
        ..._.slice(state.subscriptions, index + 1),
      ],
    });
  }

  switch (action.type) {
    case types.WEBSOCKET_CONNECTED: {
      return ({
        ...state,
        isConnected: true,
      });
    }
    case types.WEBSOCKET_DISCONNECTED: {
      return ({
        ...state,
        isConnected: false,
      });
    }
    case types.WEBSOCKET_REMOVE_SUBSCRIPTION: {
      return ({
        ...state,
        subscriptions: _.filter(state.subscriptions, s => s.topic !== action.topic),
      });
    }
    default:
      return state;
  }
}

// selectors
export function isConnected(state) {
  return state.websocket.isConnected;
}

export function getSubscriptions(state) {
  return state.websocket.subscriptions;
}