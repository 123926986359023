import _ from 'lodash';

const SagaUtils = {
  extractDeviceMeta(devices) {
    // Extract all joined information and put into separate reducers
    // Then, clean up the initial device
    const shortlist = [];
    const sites = [];
    const alertConfigs = [];
    const stateTree = {};
    _.forEach(devices, (d) => {
      if (d.shortlist) {
        shortlist.push(d.id);
        delete d.shortlist; // eslint-disable-line no-param-reassign
      }
      if (d.app_site) {
        sites.push(d.app_site);
        delete d.app_site;  // eslint-disable-line no-param-reassign
      }
      if (d.alert_configs) {
        alertConfigs.push(...d.alert_configs);
        delete d.alert_configs; // eslint-disable-line no-param-reassign
      }
      if (d.static_state) {
        stateTree[d.id] = {
          static: d.static_state,
          dynamic: undefined,
        };
        delete d.static_state; // eslint-disable-line no-param-reassign
      }
      if (d.dynamic_state) {
        stateTree[d.id] = _.assign({}, stateTree[d.id], {
          dynamic: d.dynamic_state,
        });
        delete d.dynamic_state; // eslint-disable-line no-param-reassign
      }
    });
    return {
      shortlist,
      sites,
      alertConfigs,
      stateTree,
    };
  },
};

export default SagaUtils;
