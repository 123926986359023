import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import alertService from '../services/alerts';
import * as alertTypes from '../store/alert/actionTypes';

export function* resetAlertConfig(action) {
  try {
    const alertConfig = yield call(alertService.resetAlertConfig, action.payload);
    yield put({ type: alertTypes.ALERT_CONFIG_RESET_SUCCEED, alertConfig });
  } catch (e) {
    yield put({ type: alertTypes.ALERT_CONFIG_RESET_FAILED, message: e.message });
  }
}

export function* updateAlertConfig(action) {
  try {
    const alertConfig = yield call(alertService.updateAlertConfig, action.payload);
    yield put({ type: alertTypes.ALERT_CONFIG_UPDATE_SUCCEED, alertConfig });
  } catch (e) {
    yield put({ type: alertTypes.ALERT_CONFIG_UPDATE_FAILED, message: e.message });
  }
}

export function* createAlertConfig(action) {
  try {
    const alertConfig = yield call(alertService.createAlertConfig, action.payload);
    yield put({ type: alertTypes.ALERT_CONFIG_CREATE_SUCCEED, alertConfig });
  } catch (e) {
    yield put({ type: alertTypes.ALERT_CONFIG_CREATE_FAILED, message: e.message });
  }
}

export function* getAlertCount(action) {
  try {
    const alertCount = yield call(alertService.getAlertCount, action.payload);
    yield put({ type: alertTypes.ALERT_COUNT_FETCH_SUCCEED, alertCount });
  } catch (e) {
    yield put({ type: alertTypes.ALERT_COUNT_FETCH_FAILED, message: e.message });
  }
}

export const alertSaga = [
  takeLatest(alertTypes.ALERT_CONFIG_RESET_REQUESTED, resetAlertConfig),
  takeEvery(alertTypes.ALERT_CONFIG_UPDATE_REQUESTED, updateAlertConfig),
  takeLatest(alertTypes.ALERT_CONFIG_CREATE_REQUESTED, createAlertConfig),
  takeLatest(alertTypes.ALERT_COUNT_FETCH_REQUESTED, getAlertCount),
];
