import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import user from './user/reducer';
import comment from './comment/reducer';
import process from './process/reducer';
import device from './device/reducer';
import alert from './alert/reducer';
import site from './site/reducer';
import error from './error/reducer';
import app from './app/reducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  comment,
  process,
  device,
  alert,
  site,
  error,
  app,
  // rest of your reducers
});

export default createRootReducer