export const APP_PROCESSES_REQUESTED = 'APP_PROCESSES_REQUESTED';
export const APP_PROCESSES_SUCCEED = 'APP_PROCESSES_SUCCEED';
export const APP_PROCESSES_FAILED = 'APP_PROCESSES_FAILED';

export const PROCESS_STATE_REQUESTED = 'PROCESS_STATE_REQUESTED';
export const PROCESS_STATE_SUCCEED = 'PROCESS_STATE_SUCCEED';
export const PROCESS_STATE_FAILED = 'PROCESS_STATE_FAILED';

export const PROCESS_DATA_REQUESTED = 'PROCESS_DATA_REQUESTED';
export const PROCESS_DATA_SUCCEED = 'PROCESS_DATA_SUCCEED';
export const PROCESS_DATA_FAILED = 'PROCESS_DATA_FAILED';

export const WEBSOCKET_PROCESS_UPDATED = 'WEBSOCKET_PROCESS_UPDATED';
