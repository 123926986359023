import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import * as userSelectors from '../store/user/reducer';
import { useDispatch } from 'react-redux';
import * as userTypes from '../store/user/actionTypes';

const AuthenticatedRoute = (props) => {
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);
  const dispatch = useDispatch();
  // const [showExpireModal, setShowExpireModal] = useState(false);

  useEffect(() => {

    setTimeout(() => {
      clearCacheData();
     dispatch({ type: userTypes.USER_UPDATED, payload: undefined }); // reset user object
    }, 120 * 60 * 1000); // after 2 hours
  }, [dispatch]);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    alert('Session has expired, please login again.')
  };

  return (
   // showExpireModal ? <SessionExpireModal onClose={onClose}/> :
    !isAuthenticated ? // changed from props.isAuthenticated (reminder to verify)
    <Redirect to="/login" /> :
    <Route {...props} />
  )
}

// const mapStateToProps = (state) => {
//   const isAuthenticated = userSelectors.isAuthenticated(state);
//   return {
//     isAuthenticated,
//   };
// };

export default AuthenticatedRoute;