import React from 'react';
import { Form, Grid, Header, Message, Segment } from 'semantic-ui-react';

const LoginView = (props) => {
  const { children } = props;
  return (
    <div
      className="login-form"
    >
      <Grid
        textAlign="center"
        style={{ height: '100%', marginTop: '200px' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="grey" textAlign="center">
            BlueRover Status
          </Header>
          <Form size="large">
            <Segment>
              {children}
            </Segment>
          </Form>
          <Message>
            Want access? <a href="!#">Request for access</a>
          </Message>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default LoginView;
