import ServiceUtils from '../utils/ServiceUtils';

class ProcessService {
  async getProcesses(token) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/apps/${token.appId}/processes`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`ProcessService getProcesses failed. Http status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async getProcessData(processId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/processes/${processId}/data`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`ProcessService getProcessData failed. Http status ${response.status}`);
    }

    const data = await response.json();
    return data;
  }
}

export default new ProcessService();