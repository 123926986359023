import _ from 'lodash';

import * as types from './actionTypes';
import { clearCacheData } from '../../sagas';

const initialState = {
  currentUser: undefined,
  shortlist: [],
};

export default function reduce(state = initialState, action = {}) {
  if (_.includes(action.message, '401')) {
    // display session expired 
    clearCacheData();
    return ({
      ...state,
      currentUser: undefined,
    })
  }

  switch (action.type) {
    case types.SHORTLIST_FETCH_SUCCEED: {
      return ({
        ...state,
        shortlist: _.uniq(_.concat(action.shortlist, state.shortlist)),
      });
    }
    case types.USER_ADD_SHORTLIST_SUCCEED: {
      if (!_.includes(state.shortlist, action.payload.deviceId)) {
        return ({
          ...state,
          shortlist: _.concat(state.shortlist, [action.payload.deviceId]),
        });
      }
      return state;
    }
    case types.USER_REMOVE_SHORTLIST_SUCCEED: {
      return ({
        ...state,
        shortlist: _.filter(state.shortlist, id => id !== action.payload.deviceId),
      });
    }
    case types.USER_UPDATED: {
      return ({
        ...state,
        currentUser: action.userData,
      });
    }
    default:
      return state;
  }
}

// selectors
export function getCurrentUser(state) {
  return state.user.currentUser;
}

export function isAuthenticated(state) {
  return !!state.user.currentUser;
}

export function getShortlist(state) {
  return state.user.shortlist;
}
