/* eslint react/no-did-mount-set-state: "off" */
import React, { Component } from 'react';

export default function asyncComponent(importComponent) {
  // Issues replacing with hooks
  // const AsyncComponent = (props) => {
  //   const [Component, setComponent] = useState(null);

  //   useEffect(() => {
  //     (async () => {
  //       const component = await importComponent();
  //       setComponent(component);
  //     })()
  //     .catch(console.error);
  //   }, []);

  //   return Component ? <Component {...props} /> : null;
  // };
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : null;
    }
  }
  return AsyncComponent;
  
}
