import ServiceUtils from '../utils/ServiceUtils';

class UserService {
  async loginGoogle(userData) {
    const url = ServiceUtils.getFullURL('/api/v1/unauth/login/google');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`UserService loginGoogle failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async logoutGoogle() {
    const url = ServiceUtils.getFullURL('/api/v1/unauth/logout/google');
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`UserService logoutGoogle failed, HTTP status ${response.status}`);
    }
    return response.ok;
  }

  async getShortlist(userId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/users/${userId}/devices`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`UserService getShortlist failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async addDeviceToShortlistForUser(userId, deviceId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/users/${userId}/devices/${deviceId}`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`UserService addDeviceToShortlistForUser failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async removeDeviceFromShortlistForUser(userId, deviceId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/users/${userId}/devices/${deviceId}`);
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`UserService addDeviceToShortlistForUser failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }
}

export default new UserService();