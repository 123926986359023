// Comments List for posting
export const COMMENTS_FETCH_REQUESTED = 'COMMENTS_FETCH_REQUESTED';
export const COMMENTS_FETCH_SUCCEED = 'COMMENTS_FETCH_SUCCEED';
export const COMMENTS_FETCH_FAILED = 'COMMENTS_FETCH_FAILED';

export const COMMENTS_ADD_REQUESTED = 'COMMENTS_ADD_REQUESTED';
export const COMMENTS_ADD_SUCCEED = 'COMMENTS_ADD_SUCCEED';
export const COMMENTS_ADD_FAILED = 'COMMENTS_ADD_FAILED';
// Comments Feed for home screen
export const COMMENTS_FEED_FETCH_REQUESTED = 'COMMENTS_FEED_FETCH_REQUESTED';
export const COMMENTS_FEED_FETCH_SUCCEED = 'COMMENTS_FEED_FETCH_SUCCEED';
export const COMMENTS_FEED_FETCH_FAILED = 'COMMENTS_FEED_FETCH_FAILED';
