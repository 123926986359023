import { call, put, takeLatest } from 'redux-saga/effects';
import appService from '../services/apps';
import * as appTypes from '../store/app/actionTypes';
import * as siteTypes from '../store/site/actionTypes';

export function* fetchSitesForApp(action) {
  try {
    const sites = yield call(appService.fetchSitesForApp, action.appId);
    yield put({ type: siteTypes.SITES_FETCH_SUCCEED, sites });
  } catch (e) {
    yield put({ type: siteTypes.SITES_FETCH_FAILED, message: e.message });
  }
}

export function* requestToBroadcastForApp(action) {
  try {
    const sendEmail = yield call(appService.requestToBroadcastForApp, action.payload);
    yield put({ type: appTypes.APP_EMAILS_SEND_SUCCEED, sendEmail });
  } catch (e) {
    yield put({ type: appTypes.APP_EMAILS_SEND_FAILED, message: e.message });
  }
}

export const appSaga = [
  takeLatest(appTypes.APP_SITES_FETCH_REQUESTED, fetchSitesForApp),
  takeLatest(appTypes.APP_EMAILS_SEND_REQUESTED, requestToBroadcastForApp),
];
