import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginContainer from './containers/LoginContainer';
import DashboardContainer from './containers/DashboardContainer';
import AuthenticatedRoute from './containers/AuthenticatedRoute';

import 'semantic-ui-css/semantic.min.css'
import './styles/css/index.css'

const App = () => (
  <div className="app-routes">
    <Switch>
      <Route path="/login" component={LoginContainer} />
      <AuthenticatedRoute path="/" component={DashboardContainer} />
    </Switch>
  </div>
);

export default App;
