import _ from 'lodash';

import * as processTypes from './actionTypes';

const initialState = {
  processes: {},
  state: {},
  data: [],
  isFetching: false,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case processTypes.APP_PROCESSES_REQUESTED:
      return ({
        ...state,
        isFetching: true,
      });
    case processTypes.APP_PROCESSES_SUCCEED: {
      const processes = {};
      const processStates = {};
      _.forEach(action.processes, (p) => {
        processes[p.id] = _.omit(p, 'process_state');
        processStates[p.id] = p.process_state;
      });
      return ({
        ...state,
        processes: {
          ...state.processes,
          ...processes,
        },
        state: {
          ...state.state,
          ...processStates,
        },
      });
    }
    case processTypes.WEBSOCKET_PROCESS_UPDATED: {
      if (action.data.process && action.data.process.id) {
        return ({
          ...state,
          processes: {
            ...state.processes,
            [action.data.process.id]: action.data.process,
          },
          state: {
            ...state.state,
            [action.data.process.id]: action.data.state,
          },
        });
      }
      return state;
    }
    case processTypes.PROCESS_DATA_SUCCEED: {
      return ({
        ...state,
        data: action.processData,
        isFetching: false,
      });
    }
    default:
      return state;
  }
}

export function getProcessesByApp(state, appId) {
  return _.filter(_.values(state.process.processes), p => p.appId === appId);
}

export function getProcessStatesById(state, processId) {
  return state.process.state[processId];
}

export function getProcessesWithStateByApp(state, appId) {
  const processes = [];
  _.forEach(state.process.processes, (p) => {
    if (p.appId === appId) {
      processes.push(_.assign({
        processState: state.process.state[p.id],
      }, p));
    }
  });
  return processes;
}

export function getProcesses(state) {
  return _.values(state.process.processes);
}

export function getProcessData(state) {
  return state.process.data;
}
