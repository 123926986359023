import ServiceUtils from '../utils/ServiceUtils';

class AppService {
  async fetchSitesForApp(appId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/apps/${appId}/sites`);
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`AppService fetchSites failed, HTTP status ${response.status}`);
    }
    const sites = await response.json();
    return sites;
  }

  async requestToBroadcastForApp(payload) {
    const url = ServiceUtils.getFullURL('/api/v1/auth/apps/broadcast');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`Request to broadcast for apps failed, HTTP status ${response.status}`);
    }
    const data = await response;
    return data;
  }
}

export default new AppService();
