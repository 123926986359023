import ServiceUtils from '../utils/ServiceUtils';

class SiteService {
  async fetchSites() {
    const url = ServiceUtils.getFullURL('/api/v1/auth/sites');
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`SiteService fetchSites failed, HTTP status ${response.status}`);
    }
    const sites = await response.json();
    return sites;
  }

  async fetchSite(id) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/sites/${id}`);
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`SiteService fetchSite failed, HTTP status ${response.status}`);
    }
    const site = await response.json();
    return site;
  }
}

export default new SiteService();