import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as types from '../store/user/actionTypes';
import * as userSelectors from '../store/user/reducer';

import GoogleSignIn from '../components/GoogleSignIn';
import LoginView from '../components/LoginView';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);
  const history = useHistory();
  
  useEffect(( ) => {
    if(isAuthenticated){
      history.push('/home');
    }
  }, [isAuthenticated, history]);

  const userLoginGoogleRequested = (payload) => dispatch({ type: types.USER_LOGIN_GOOGLE_REQUESTED, payload });

  const onGoogleSignIn = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    const userData = {
      id_token: googleUser.getAuthResponse().id_token,
      email: profile.getEmail(),
    };
    userLoginGoogleRequested(userData);
  }
  
  return (
    <LoginView>
      <GoogleSignIn onSuccess={onGoogleSignIn} />
    </LoginView>
  );


}

export default LoginContainer;
