import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

import deviceService from '../services/devices';
import * as deviceTypes from '../store/device/actionTypes';
import * as deviceSelectors from '../store/device/reducer';
import * as alertTypes from '../store/alert/actionTypes';
import * as siteTypes from '../store/site/actionTypes';
import * as siteSelectors from '../store/site/reducer';
import * as errorTypes from '../store/error/actionTypes';

import SagaUtils from '../utils/SagaUtils';

export function* fetchDevices(action) {
  try {
    const devices = yield call(deviceService.getDevices, action.payload);
    const { sites, alertConfigs, stateTree } = SagaUtils.extractDeviceMeta(devices);
    yield put({ type: alertTypes.ALERT_CONFIGS_FETCH_SUCCEED, alertConfigs });
    yield put({ type: siteTypes.SITES_FETCH_SUCCEED, sites });
    yield put({ type: deviceTypes.DEVICES_STATE_UPDATE, state: stateTree });
    yield put({ type: deviceTypes.DEVICES_FETCH_SUCCEED, devices });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchDevice(action) {
  try {
    const device = yield call(deviceService.getDevice, action.payload);
    const { sites, alertConfigs, stateTree } = SagaUtils.extractDeviceMeta([device]);
    yield put({ type: alertTypes.ALERT_CONFIGS_FETCH_SUCCEED, alertConfigs });
    yield put({ type: siteTypes.SITES_FETCH_SUCCEED, sites });
    yield put({ type: deviceTypes.DEVICES_STATE_UPDATE, state: stateTree });
    yield put({ type: deviceTypes.DEVICE_FETCH_SUCCEED, device });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICE_FETCH_FAILED, message: e.message });
  }
}

export function* updateDevice(action) {
  try {
    yield put({ type: errorTypes.RESET_ERROR_STATE });
    const device = yield call(deviceService.updateDevice, action.payload);

    const appSiteId = device.appSiteId;
    if (appSiteId !== null) {
      const site = yield select(siteSelectors.getSite, appSiteId);
      if (!site) {
        yield put({ type: siteTypes.SITE_FETCH_REQUESTED, payload: appSiteId });
      }
    }
    yield put({ type: deviceTypes.DEVICE_UPDATE_SUCCEED, device });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICE_UPDATE_FAILED, message: e.message });
  }
}

export function* fetchDevicesCount() {
  try {
    const deviceCountData = yield call(deviceService.getDeviceCount);
    yield put({ type: deviceTypes.DEVICES_COUNT_FETCH_SUCCEED, deviceCountData });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICES_COUNT_FETCH_FAILED, message: e.message });
  }
}

export function* fetchDeviceStaticData(action) {
  try {
    const deviceData = yield call(deviceService.getDeviceData, 'static', action.payload);
    yield put({ type: deviceTypes.DEVICES_STATIC_DATA_FETCH_SUCCEED, deviceData });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICES_STATIC_DATA_FETCH_FAILED, message: e.message });
  }
}

export function* fetchDeviceDynamicData(action) {
  try {
    const deviceData = yield call(deviceService.getDeviceData, 'dynamic', action.payload);
    yield put({ type: deviceTypes.DEVICES_DYNAMIC_DATA_FETCH_SUCCEED, deviceData });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICES_DYNAMIC_DATA_FETCH_FAILED, message: e.message });
  }
}

export function* fetchSiteDevices(action) {
  try {
    const site = action.payload;
    const oldDevices = yield select(deviceSelectors.getDeviceListForSite, site.id);

    const devices = yield call(deviceService.getSiteDevices, site);
    const { stateTree } = SagaUtils.extractDeviceMeta(devices);
    const oldDeviceIds = _.difference(_.map(oldDevices, 'id'), _.map(devices, 'id'));

    yield put({ type: deviceTypes.DEVICES_STATE_UPDATE, state: stateTree });
    if (oldDeviceIds.length > 0) {
      yield put({
        type: deviceTypes.SITE_DEVICES_UNASSIGN_SITE,
        deviceIds: oldDeviceIds,
        siteId: site.id,
      });
    }
    yield put({ type: deviceTypes.DEVICES_FETCH_SUCCEED, devices });
  } catch (e) {
    yield put({ type: deviceTypes.SITE_DEVICES_FETCH_FAILED, message: e.message });
  }
}

export function* publishToDevice(action) {
  try {
    const message = yield call(deviceService.publishToDevice, action.payload);
    yield put({ type: errorTypes.RESET_ERROR_STATE });
    yield put({ type: deviceTypes.DEVICE_PUBLISH_SUCCEED, isPublishing: false, message});
  } catch (e) {
    yield put({ type: deviceTypes.DEVICE_PUBLISH_FAILED, message: e.message, isPublishing: false });
  }
}

export function* fetchAlertConfigs(action) {
  try {
    const alertConfigs = yield call(deviceService.getAlertConfigs, action.payload);
    yield put({ type: alertTypes.ALERT_CONFIGS_FETCH_SUCCEED, alertConfigs });
  } catch (e) {
    yield put({ type: alertTypes.ALERT_CONFIGS_FETCH_FAILED, message: e.message });
  }
}

export function* fetchStaticState(action) {
  try {
    const state = yield call(deviceService.getState, 'static', action.payload);
    yield put({ type: deviceTypes.DEVICE_STATIC_STATE_UPDATE, state });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICE_STATIC_STATE_FAILED, message: e.message });
  }
}

export function* fetchDynamicState(action) {
  try {
    const state = yield call(deviceService.getState, 'dynamic', action.payload);
    yield put({ type: deviceTypes.DEVICE_DYNAMIC_STATE_UPDATE, state });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICE_DYNAMIC_STATE_FAILED, message: e.message });
  }
}

export function* fetchTimelineData(action) {
  try {
    const deviceData = yield call(deviceService.getTimelineData, action.payload);
    yield put({ type: deviceTypes.DEVICE_TIMELINE_DATA_FETCH_SUCCEED, deviceData, isDataGathering: false });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICE_TIMELINE_DATA_FETCH_FAILED, message: e.message, isDataGathering: false });
  }
}

export function* bulkUpdateDeviceData(action) {
  try {
    const data = yield call(deviceService.bulkUpdateDevices, action.payload);
    yield put({ type: deviceTypes.DEVICES_BULK_UPDATE_SUCCEED, data });
  } catch (e) {
    yield put({
      type: deviceTypes.DEVICES_BULK_UPDATE_FAILED,
      message: e.message,
      isBulkUpdating: false,
    });
  }
}

export function* registerDevice(action) {
  try {
    const device = yield call(deviceService.registerDevice, action.payload);
    yield put({ type: deviceTypes.DEVICE_REGISTER_SUCCEED, device });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICE_REGISTER_FAILED, message: e.message });
  }
}

export function* bulkRegisterDevices(action) {
  try {
    yield put({ type: errorTypes.RESET_ERROR_STATE });
    const devices = action.payload;
    for (let i = 0; i < devices.length; i += 1) {
      const device = yield call(deviceService.registerDevice, devices[i]);
      yield put({ type: deviceTypes.DEVICE_REGISTER_SUCCEED, device });
    }
    yield put({ type: deviceTypes.DEVICES_BULK_REGISTER_SUCCEED });
  } catch (e) {
    yield put({ type: deviceTypes.DEVICES_BULK_REGISTER_FAILED, message: e.message });
  }
}

export const deviceSaga = [
  takeLatest(deviceTypes.DEVICES_FETCH_REQUESTED, fetchDevices),
  takeEvery(deviceTypes.DEVICES_COUNT_FETCH_REQUESTED, fetchDevicesCount),
  takeLatest(deviceTypes.DEVICE_FETCH_REQUESTED, fetchDevice),
  takeLatest(deviceTypes.DEVICE_UPDATE_REQUESTED, updateDevice),

  takeLatest(deviceTypes.DEVICES_STATIC_DATA_FETCH_REQUESTED, fetchDeviceStaticData),
  takeLatest(deviceTypes.DEVICES_DYNAMIC_DATA_FETCH_REQUESTED, fetchDeviceDynamicData),

  takeLatest(deviceTypes.DEVICE_PUBLISH_REQUESTED, publishToDevice),

  takeLatest(siteTypes.SITE_DEVICES_FETCH_REQUESTED, fetchSiteDevices),

  takeLatest(alertTypes.ALERT_CONFIGS_FETCH_REQUESTED, fetchAlertConfigs),

  takeLatest(deviceTypes.DEVICE_STATIC_STATE_REQUESTED, fetchStaticState),
  takeLatest(deviceTypes.DEVICE_DYNAMIC_STATE_REQUESTED, fetchDynamicState),

  takeLatest(deviceTypes.DEVICE_TIMELINE_DATA_FETCH_REQUESTED, fetchTimelineData),
  takeLatest(deviceTypes.DEVICES_BULK_UPDATE_REQUESTED, bulkUpdateDeviceData),
  takeLatest(deviceTypes.DEVICES_BULK_REGISTER_REQUESTED, bulkRegisterDevices),
  takeEvery(deviceTypes.DEVICE_REGISTER_REQUESTED, registerDevice),
];
