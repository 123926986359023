export const DEVICES_COUNT_FETCH_SUCCEED = 'DEVICES_COUNT_FETCH_SUCCEED';
export const DEVICES_COUNT_FETCH_REQUESTED = 'DEVICES_COUNT_FETCH_REQUESTED';
export const DEVICES_COUNT_FETCH_FAILED = 'DEVICES_COUNT_FETCH_FAILED';
export const DEVICES_FETCH_SUCCEED = 'DEVICES_FETCH_SUCCEED';
export const DEVICES_FETCH_REQUESTED = 'DEVICES_FETCH_REQUESTED';
export const DEVICES_FETCH_FAILED = 'DEVICES_FETCH_FAILED';
export const DEVICE_FETCH_REQUESTED = 'DEVICE_FETCH_REQUESTED';
export const DEVICE_FETCH_SUCCEED = 'DEVICE_FETCH_SUCCEED';
export const DEVICE_FETCH_FAILED = 'DEVICE_FETCH_FAILED';
export const DEVICE_UPDATE_REQUESTED = 'DEVICE_UPDATE_REQUESTED';
export const DEVICE_UPDATE_SUCCEED = 'DEVICE_UPDATE_SUCCEED';
export const DEVICE_UPDATE_FAILED = 'DEVICE_UPDATE_FAILED';

export const DEVICES_STATIC_DATA_FETCH_REQUESTED = 'DEVICES_STATIC_DATA_FETCH_REQUESTED';
export const DEVICES_STATIC_DATA_FETCH_SUCCEED = 'DEVICES_STATIC_DATA_FETCH_SUCCEED';
export const DEVICES_STATIC_DATA_FETCH_FAILED = 'DEVICES_STATIC_DATA_FETCH_FAILED';
export const DEVICES_DYNAMIC_DATA_FETCH_REQUESTED = 'DEVICES_DYNAMIC_DATA_FETCH_REQUESTED';
export const DEVICES_DYNAMIC_DATA_FETCH_SUCCEED = 'DEVICES_DYNAMIC_DATA_FETCH_SUCCEED';
export const DEVICES_DYNAMIC_DATA_FETCH_FAILED = 'DEVICES_DYNAMIC_DATA_FETCH_FAILED';

export const SITE_DEVICES_UNASSIGN_SITE = 'SITE_DEVICES_UNASSIGN_SITE';
export const SITE_DEVICES_FETCH_FAILED = 'SITE_DEVICES_FETCH_FAILED';

export const DEVICE_PUBLISH_REQUESTED = 'DEVICE_PUBLISH_REQUESTED';
export const DEVICE_PUBLISH_SUCCEED = 'DEVICE_PUBLISH_SUCCEED';
export const DEVICE_PUBLISH_FAILED = 'DEVICE_PUBLISH_FAILED';

export const DEVICES_STATE_UPDATE = 'DEVICES_STATE_UPDATE';
export const DEVICE_STATIC_STATE_REQUESTED = 'DEVICE_STATIC_STATE_REQUESTED';
export const DEVICE_STATIC_STATE_UPDATE = 'DEVICE_STATIC_STATE_UPDATE';
export const DEVICE_STATIC_STATE_FAILED = 'DEVICE_STATIC_STATE_FAILED';
export const DEVICE_DYNAMIC_STATE_REQUESTED = 'DEVICE_DYNAMIC_STATE_REQUESTED';
export const DEVICE_DYNAMIC_STATE_UPDATE = 'DEVICE_DYNAMIC_STATE_UPDATE';
export const DEVICE_DYNAMIC_STATE_FAILED = 'DEVICE_DYNAMIC_STATE_FAILED';

export const WEBSOCKET_DEVICE_UPDATED = 'WEBSOCKET_DEVICE_UPDATED';
export const WEBSOCKET_DEVICE_FETCHED = 'WEBSOCKET_DEVICE_FETCHED';
export const WEBSOCKET_DEVICE_STATIC_STATE_UPDATED = 'WEBSOCKET_DEVICE_STATIC_STATE_UPDATED';
export const WEBSOCKET_DEVICE_DYNAMIC_STATE_UPDATED = 'WEBSOCKET_DEVICE_DYNAMIC_STATE_UPDATED';
export const WEBSOCKET_DEVICE_STATIC_DATA_UPDATED = 'WEBSOCKET_DEVICE_STATIC_DATA_UPDATED';
export const WEBSOCKET_DEVICE_DYNAMIC_DATA_UPDATED = 'WEBSOCKET_DEVICE_DYNAMIC_DATA_UPDATED';
export const WEBSOCKET_DEVICE_COUNT_UPDATED = 'WEBSOCKET_DEVICE_COUNT_UPDATED';

export const RESET_DEVICE_DATA = 'RESET_DEVICE_DATA';
export const DEVICE_TIMELINE_DATA_FETCH_REQUESTED = 'DEVICE_TIMELINE_DATA_FETCH_REQUESTED';
export const DEVICE_TIMELINE_DATA_FETCH_SUCCEED = 'DEVICE_TIMELINE_DATA_FETCH_SUCCEED';
export const DEVICE_TIMELINE_DATA_FETCH_FAILED = 'DEVICE_TIMELINE_DATA_FETCH_FAILED';

export const DEVICES_BULK_UPDATE_REQUESTED = 'DEVICES_BULK_UPDATE_REQUESTED';
export const DEVICES_BULK_UPDATE_SUCCEED = 'DEVICES_BULK_UPDATE_SUCCEED';
export const DEVICES_BULK_UPDATE_FAILED = 'DEVICES_BULK_UPDATE_FAILED';

export const DEVICES_BULK_REGISTER_REQUESTED = 'DEVICES_BULK_REGISTER_REQUESTED';
export const DEVICES_BULK_REGISTER_SUCCEED = 'DEVICES_BULK_REGISTER_SUCCEED';
export const DEVICES_BULK_REGISTER_FAILED = 'DEVICES_BULK_REGISTER_FAILED';
export const DEVICE_REGISTER_REQUESTED = 'DEVICE_REGISTER_REQUESTED';
export const DEVICE_REGISTER_SUCCEED = 'DEVICE_REGISTER_SUCCEED';
export const DEVICE_REGISTER_FAILED = 'DEVICE_REGISTER_FAILED';
