import _ from 'lodash';

import * as types from './actionTypes';

const initialState = {
  configs: {}, // { [deviceId]: [ ...configs ]}
  state: {},
  alertData: {},
  alertCount: {},
  isUpdating: false,
  error: {},
};

export function getAlertStateForConfig(state, alertConfigId) {
  return state.alert.state[alertConfigId];
}

export function getWorstStateForDevice(state, deviceId) {
  let worstState = 'OK';
  const alertConfigs = state.alert.configs[deviceId];
  if (!alertConfigs) {
    return worstState;
  }
  for (let i = 0; i < alertConfigs.length; i += 1) {
    const alertState = getAlertStateForConfig(state, alertConfigs[i].id);
    const s = alertState ? alertState.state : 'OK';
    switch (s) {
      case 'ALERT':
        return 'ALERT';
      case 'IN_PROGRESS':
        worstState = 'IN_PROGRESS';
        break;
      case 'OK':
      default:
        break;
    }
  }

  return worstState;
}

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.ALERT_CONFIGS_FETCH_SUCCEED: {
      const newAlertStates = {};
      const newAlertConfigs = _.reduce(action.alertConfigs, (result, config) => {
        if (!result[config.deviceId]) {
          _.assign(result, {
            [config.deviceId]: [],
          });
        }
        newAlertStates[config.id] = config.alert_state;
        result[config.deviceId].push(_.omit(config, 'alert_state'));
        return result;
      }, {});
      return ({
        ...state,
        configs: {
          ...state.configs,
          ...newAlertConfigs,
        },
        state: {
          ...state.state,
          ...newAlertStates,
        },
        isUpdating: false,
      });
    }
    case types.ALERT_CONFIG_RESET_SUCCEED:
    case types.ALERT_CONFIG_UPDATE_SUCCEED:
    case types.ALERT_CONFIG_CREATE_SUCCEED:
    case types.ALERT_CONFIG_FETCH_SUCCEED: {
      let deviceAlertConfigs = state.configs[action.alertConfig.deviceId] || [];
      console.log(deviceAlertConfigs);
      console.log('action: ', action);
      const index = _.findIndex(deviceAlertConfigs, config => config.id === action.alertConfig.id);
      if (index === -1) {
        deviceAlertConfigs = [
          ...deviceAlertConfigs,
          ([_.omit(action.alertConfig, 'alert_state')])
        ];
      } else {
        // deviceAlertConfigs = deviceAlertConfigs.set(index, _.omit(action.alertConfig, 'alert_state'));
        deviceAlertConfigs[index] = _.omit(action.alertConfig, 'alert_state');
      }
      return ({
        ...state,
        configs: {
          ...state.configs,
          [action.alertConfig.deviceId]: deviceAlertConfigs,
        },
        state: {
          ...state.state,
          [action.alertConfig.id]: action.alertConfig.alert_state,
        },
        isUpdating: false,
      });
    }
    case types.ALERT_CONFIG_UPDATE_REQUESTED:
    case types.ALERT_CONFIG_CREATE_REQUESTED:
      return ({
        ...state,
        isUpdating: true,
      });
    case types.WEBSOCKET_ALERT_STATE_UPDATED: {
      const device = action.data.device;
      const deviceAlertConfigs = state.configs[device.id];
      if (deviceAlertConfigs == null || deviceAlertConfigs.length === 0) {
        return state;
      }
      const index = _.findIndex(
        deviceAlertConfigs,
        c => c.id === action.data.alertState.alertConfigId,
      );
      if (index !== -1) {
        // we also update the count of alert devices if necessary
        const previousWorstState = getWorstStateForDevice({
          alert: state,
        }, device.id);
        let newState = ({
          state: {
            ...state.state,
            [action.data.alertState.alertConfigId]: action.data.alertState,
          },
        });
        const newWorstState = getWorstStateForDevice({
          alert: newState,
        }, device.id);
        if (previousWorstState !== newWorstState) {
          newState = ({
            ...newState,
            alertCount: {
              ...newState.alertCount,
              [device.deviceType]: {
                ...newState.alertCount[device.deviceType],
                [previousWorstState]: newState.alertCount[device.deviceType][previousWorstState] - 1, // eslint-disable-line max-len
                [newWorstState]: newState.alertCount[device.deviceType][newWorstState] + 1,
              },
            },
          });
        }
        return newState;
      }
      return state;
    }
    case types.ALERT_COUNT_FETCH_SUCCEED: {
      return ({
        ...state,
        alertCount: action.alertCount,
      });
    }
    case types.ALERT_CONFIG_UPDATE_FAILED: {
      return ({
        ...state,
        isUpdating: false,
        error: action,
      })
    }
    default:
      return state;
  }
}

export function getAlertConfigs(state) {
  return state.alert.configs;
}

export function getAlertConfigsByDevice(state, deviceId) {
  return state.alert.configs[deviceId];
}

export function getAlertData(state) {
  return state.alert.alertData;
}

export function getIsUpdating(state) {
  return state.alert.isUpdating;
}

export function getAlertCountByDeviceType(state, deviceType) {
  return state.alert.alertCount[deviceType];
}

export function getAlertError(state) {
  return state.alert.error;
}