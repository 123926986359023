import _ from 'lodash';

import * as types from './actionTypes';

const initialState = {
  error: '',
};

export default function reduce(state = initialState, action = {}) {
  if (_.includes(action.type, 'FAILED')) {
    return ({
      ...state,
      error: action.message,
    });
  } else if (action.type === types.RESET_ERROR_STATE) {
    return ({
      ...state,
      error: '',
    });
  }
  return state;
}

export function getError(state) {
  return state.error.error;
}
