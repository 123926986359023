export const USER_UPDATED = 'USER_UPDATED';
export const USER_LOGIN_GOOGLE_REQUESTED = 'USER_LOGIN_GOOGLE_REQUESTED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGOUT_GOOGLE_REQUESTED = 'USER_LOGOUT_GOOGLE_REQUESTED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const USER_ADD_SHORTLIST_REQUESTED = 'USER_ADD_SHORTLIST_REQUESTED';
export const USER_ADD_SHORTLIST_SUCCEED = 'USER_ADD_SHORTLIST_SUCCEED';
export const USER_ADD_SHORTLIST_FAILED = 'USER_ADD_SHORTLIST_FAILED';
export const USER_REMOVE_SHORTLIST_REQUESTED = 'USER_REMOVE_SHORTLIST_REQUESTED';
export const USER_REMOVE_SHORTLIST_SUCCEED = 'USER_REMOVE_SHORTLIST_SUCCEED';
export const USER_REMOVE_SHORTLIST_FAILED = 'USER_REMOVE_SHORTLIST_FAILED';

export const SHORTLIST_FETCH_REQUESTED = 'SHORTLIST_FETCH_REQUESTED';
export const SHORTLIST_FETCH_SUCCEED = 'SHORTLIST_FETCH_SUCCEED';
export const SHORTLIST_FETCH_FAILED = 'SHORTLIST_FETCH_FAILED';
export const SHORTLIST_FETCH_UPDATED = 'SHORTLIST_FETCH_UPDATED';
