export const ALERT_CONFIGS_FETCH_REQUESTED = 'ALERT_CONFIGS_FETCH_REQUESTED';
export const ALERT_CONFIGS_FETCH_SUCCEED = 'ALERT_CONFIGS_FETCH_SUCCEED';
export const ALERT_CONFIGS_FETCH_FAILED = 'ALERT_CONFIGS_FETCH_FAILED';
export const ALERT_CONFIG_FETCH_SUCCEED = 'ALERT_CONFIG_FETCH_SUCCEED';

export const ALERT_CONFIG_RESET_REQUESTED = 'ALERT_CONFIG_RESET_REQUESTED';
export const ALERT_CONFIG_RESET_SUCCEED = 'ALERT_CONFIG_RESET_SUCCEED';
export const ALERT_CONFIG_RESET_FAILED = 'ALERT_CONFIG_RESET_FAILED';

export const ALERT_CONFIG_UPDATE_REQUESTED = 'ALERT_CONFIG_UPDATE_REQUESTED';
export const ALERT_CONFIG_UPDATE_SUCCEED = 'ALERT_CONFIG_UPDATE_SUCCEED';
export const ALERT_CONFIG_UPDATE_FAILED = 'ALERT_CONFIG_UPDATE_FAILED';

export const ALERT_CONFIG_CREATE_REQUESTED = 'ALERT_CONFIG_CREATE_REQUESTED';
export const ALERT_CONFIG_CREATE_SUCCEED = 'ALERT_CONFIG_CREATE_SUCCEED';
export const ALERT_CONFIG_CREATE_FAILED = 'ALERT_CONFIG_CREATE_FAILED';

export const WEBSOCKET_ALERT_STATE_UPDATED = 'WEBSOCKET_ALERT_STATE_UPDATED';

export const ALERT_COUNT_FETCH_REQUESTED = 'ALERT_COUNT_FETCH_REQUESTED';
export const ALERT_COUNT_FETCH_SUCCEED = 'ALERT_COUNT_FETCH_SUCCEED';
export const ALERT_COUNT_FETCH_FAILED = 'ALERT_COUNT_FETCH_FAILED';
