import _ from 'lodash';
import moment from 'moment';

import * as types from './actionTypes';

const initialState = {
  sites: [],
  selectedSite: {},
};

export default function reduce(state = initialState, action = {}) {
  console.log({state});
  switch (action.type) {
    case types.SITES_FETCH_SUCCEED: {
      const sites = _.reduce(_.concat(state.sites, action.sites), (result, newSite) => {
        const index = _.findIndex(result, s => (
          s.siteId === newSite.siteId && s.appId === newSite.appId
        ));
        if (index === -1) {
          result.push(newSite);
        } else {
          const oldSite = result[index];
          if (moment(newSite.updatedAt).isAfter(oldSite.updatedAt)) {
            _.assign(result[index], newSite);
          }
        }
        return result;
      }, []);

      return ({
        ...state,
        sites,
      });
    }
    case types.SITE_FETCH_SUCCEED: {
      const newSite = action.site;
      const index = _.findIndex(state.sites, s => (
        s.siteId === newSite.siteId && s.appId === newSite.appId
      ));
      if (index === -1) {
        return ({
          ...state,
          sites: [
            ...state.sites,
            newSite,
          ],
        });
      }
      return ({
        ...state,
        sites: [
          ...state.sites.slice(0, index),
          {
            ...state.sites[index],
            ...action.site,
          },
          ...state.sites.slice(index + 1),
        ],
      });
    }
    case types.SITE_DEVICES_FETCH_REQUESTED:
    case types.SET_SELECTED_SITE: {
      return ({
        ...state,
        selectedSite: action.payload,
      });
    }
    case types.WEBSOCKET_SITE_UPDATED: {
      const index = _.findIndex(state.sites, d => d.id === action.site.id);

      if (index === -1) {
        return ({
          ...state,
          sites: _.concat(state.sites, action.site),
        });
      }

      return ({
        ...state,
        sites: [
          ...state.sites.slice(0, index),
          {
            ...state.sites[index],
            ...action.site,
          },
          ...state.sites.slice(index + 1),
        ],
      });
    }

    default:
      return state;
  }
}

// selectors
export function getSites(state) {
  return state.site.sites;
}

export function getSelectedSite(state) {
  return state.site.selectedSite;
}

export function getSite(state, appSiteId) {
  return _.find(state.site.sites, s => s.id === appSiteId);
}

export function getSitesByAppId(state, appId) {
  return _.filter(state.site.sites, s => s.appId === appId);
}
