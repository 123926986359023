import ServiceUtils from '../utils/ServiceUtils';

class AlertService {
  async resetAlertConfig(alertConfigId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/alert-configs/${alertConfigId}/reset`);
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`AlertService resetAlertConfig failed, HTTP status ${response.status}`);
    }
    const alertConfig = await response.json();
    return alertConfig;
  }

  async updateAlertConfig(config) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/alert-configs/${config.id}`);
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`AlertService updateAlertConfig failed, HTTP status ${response.status}`);
    }
    const alertConfig = await response.json();
    return alertConfig;
  }

  async createAlertConfig(config) {
    const url = ServiceUtils.getFullURL('/api/v1/auth/alert-configs/');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`AlertService createAlertConfig failed, HTTP status ${response.status}`);
    }
    const alertConfig = await response.json();
    return alertConfig;
  }

  async getAlertCount() {
    const url = ServiceUtils.getFullURL('/api/v1/auth/alerts/count');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`AlertService getAlertCount failed, HTTP status ${response.status}`);
    }
    const alertCount = await response.json();
    return alertCount;
  }
}

export default new AlertService();