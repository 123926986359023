/* eslint camelcase: "off" */

import _ from 'lodash';

import * as deviceTypes from './actionTypes';
import * as userTypes from '../user/actionTypes';
import * as siteTypes from '../site/actionTypes';

const initialState = {
  devices: [],
  deviceCountData: {},
  deviceData: [], // only used for a single device
  isFetching: false,
  isPublishing: false,
  isPublishSucceed: false,
  publishedMessage: {},
  isUpdating: false,
  isBulkUpdating: false,
  isBulkSucceed: false,
  isRegistering: false,
  isDataGathering: false,
  state: {},
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case deviceTypes.DEVICES_FETCH_REQUESTED:
    case userTypes.SHORTLIST_FETCH_REQUESTED:
    case siteTypes.SITE_DEVICES_FETCH_REQUESTED:
      return {
        ...state, 
        isFetching: true
      }
    case deviceTypes.DEVICE_TIMELINE_DATA_FETCH_REQUESTED:
      return {
        ...state,
        isDataGathering: true,
      }
    case deviceTypes.DEVICES_FETCH_SUCCEED: {
      const newDevices = action.devices;
      const devices = _.map(state.devices, (device) => {
        const index = _.findIndex(newDevices, d => d.id === device.id);
        if (index !== -1) {
          return newDevices.splice(index, 1)[0];
        }
        return device;
      });
      // we concat to the back any devices we didn't update
      return {
        ...state,
        devices: _.concat(devices, newDevices),
        isFetching: false,
      };
    }
    case deviceTypes.DEVICE_FETCH_SUCCEED:
    case deviceTypes.DEVICE_UPDATE_SUCCEED:
    case deviceTypes.DEVICE_REGISTER_SUCCEED:
    case deviceTypes.WEBSOCKET_DEVICE_FETCHED: {
      const index = _.findIndex(state.devices, d => d.id === action.device.id);
      if (index !== -1) {
        return {
          ...state,
          devices: [
            ...state.devices.slice(0, index),
            {
              ...state.devices[index],
              ...action.device,
            },
            ...state.devices.slice(index + 1),
          ],
          isFetching: false,
          isUpdating: false,
        }
      }
      return {
        ...state,
        devices: [
          ...state.devices,
          action.device,
        ],
        isFetching: false,
        isUpdating: false,
      }
    }
    case deviceTypes.DEVICES_COUNT_FETCH_SUCCEED:
      return {
        ...state,
        deviceCountData: action.deviceCountData,
        isFetching: false,
      };
    case userTypes.SHORTLIST_FETCH_FAILED:
    case deviceTypes.DEVICES_FETCH_FAILED:
      return {
        ...state, 
        isFetching: false
      };
    case deviceTypes.DEVICE_TIMELINE_DATA_FETCH_FAILED:
      return {
        ...state,
        isDataGathering: false,
      }
    case deviceTypes.DEVICES_STATIC_DATA_FETCH_SUCCEED:
    case deviceTypes.DEVICES_DYNAMIC_DATA_FETCH_SUCCEED:
      return {
        ...state,
        deviceData: action.deviceData,
        isFetching: false
      }
    case deviceTypes.DEVICE_TIMELINE_DATA_FETCH_SUCCEED:
      return {
        ...state, 
        deviceData: [
          ...state.deviceData,
          ...action.deviceData
        ],
        isDataGathering: false,
      }
    case deviceTypes.DEVICES_STATE_UPDATE: {
      return {
        ...state,
        state: {
          ...state.state,
          ...action.state
        }
      }
    }
    case deviceTypes.DEVICE_DYNAMIC_STATE_UPDATE:
      return {
        ...state,
        state: {
          ...state.state,
          [action.state.deviceId]: {
            ...state.state[action.state.deviceId],
            dynamic: action.state,
          }
        }
      }
    case deviceTypes.DEVICE_STATIC_STATE_UPDATE:
      return {
        ...state,
        state: {
          ...state.state,
          [action.state.deviceId]: {
            ...state.state[action.state.deviceId],
            static: action.state,
          }
        }
      }
    case deviceTypes.DEVICE_PUBLISH_REQUESTED:
      return ({
        ...state,
        isPublishing: true,
        isPublishSucceed: false,
      });
    case deviceTypes.DEVICE_PUBLISH_SUCCEED:
      return ({
        ...state,
        isPublishSucceed: true,
        isPublishing: false,
        publishedMessage: action.message
      })
    case deviceTypes.DEVICE_PUBLISH_FAILED:
      return ({
        ...state,
        isPublishing: false,
        isPublishSucceed: false,
      });
    case deviceTypes.DEVICES_BULK_UPDATE_REQUESTED:
      return {
        ...state,
        isBulkUpdating: true,
        isBulkSucceed: false,
      }
    case deviceTypes.DEVICES_BULK_UPDATE_SUCCEED:
      return ({
        ...state,
        isBulkSucceed: true,
        isBulkUpdating: false,
      })
    case deviceTypes.DEVICES_BULK_UPDATE_FAILED:
      return {
        ...state,
        isBulkUpdating: false,
        isBulkSucceed: false
      }
    case deviceTypes.WEBSOCKET_DEVICE_COUNT_UPDATED: {
      if (action.data.new) {
        const deviceType = action.data.device.deviceType;
        return {
          ...state,
          deviceCountData: {
            ...state.deviceCountData,
            [deviceType]: state.deviceCountData[deviceType] + 1,
          },
        }
      }
      return state;
    }
    case deviceTypes.WEBSOCKET_DEVICE_UPDATED: {
      const index = _.findIndex(state.devices, d => d.id === action.device.id);
      if (index === -1) {
        return state;
      }

      let deviceState;
      if (action.dataTable === 'dynamic_data' || 
          action.dataTable === 'atracks_data' || 
          action.dataTable === 'bluemobile_dynamic_data'
          ) {
        deviceState = {
          ...state.state,
          [action.device.id]: {
            ...state.state[action.device.id],
            dynamic: action.state,
          },
        };
      } else if (action.dataTable === 'static_data' || action.dataTable === 'bluemobile_static_data') {
        deviceState = {
          ...state.state,
          [action.device.id]: {
            ...state.state[action.device.id],
            static: action.state,
          },
        };
      }
      return {
        ...state,
        devices: [
          ...state.devices.slice(0, index),
          {
            ...state.devices[index],
            ...action.device,
          },
          ...state.devices.slice(index + 1),
        ],
        state: deviceState || state.state,
      }
    }
    case deviceTypes.WEBSOCKET_DEVICE_DYNAMIC_DATA_UPDATED: 
    case deviceTypes.WEBSOCKET_DEVICE_STATIC_DATA_UPDATED: {
      if (
        state.deviceData.length === 0 ||
        state.deviceData[0].deviceId === action.data.deviceId
      ) {
        return {
          ...state,
          deviceData: [
            ...state.deviceData,
            action.data,
          ],
        }
      }
      return state;
    }
    case deviceTypes.WEBSOCKET_DEVICE_DYNAMIC_STATE_UPDATED:
      return {
        ...state,
        state: {
          ...state.state,
          [action.data.deviceId]: {
            ...state.state[action.data.deviceId],
            dynamic: action.data,
          },
        },
      }
    case deviceTypes.WEBSOCKET_DEVICE_STATIC_STATE_UPDATED:
      return {
        ...state,
        state: {
          ...state.state,
          [action.data.deviceId]: {
            ...state.state[action.data.deviceId],
            static: action.data,
          },
        },
      }
    case deviceTypes.SITE_DEVICES_UNASSIGN_SITE: {
      const { deviceIds, siteId } = action;
      const devices = _.map(state.devices, (device) => {
        if (_.includes(deviceIds, device.id) && device.appSiteId === siteId) {
          return device['appSiteId'] = null; // maybe can't assign as null b/c doesn't realize it gets changed unless page is refreshed
        }
        return device;
      });
      return {
        ...state,
        devices
      } 
    }
    case deviceTypes.RESET_DEVICE_DATA:
      return ({
        ...state,
        deviceData: []
      });
    case deviceTypes.DEVICE_UPDATE_REQUESTED: {
      return {
        ...state,
        isUpdating: true,
      }
    }
    case deviceTypes.DEVICE_UPDATE_FAILED: {
      return {
        ...state,
        isUpdating: false,
      }
    }
    case deviceTypes.DEVICES_BULK_REGISTER_REQUESTED:
      return {
        ...state,
        isRegistering: true,
      }
    case deviceTypes.DEVICES_BULK_REGISTER_SUCCEED:
    case deviceTypes.DEVICES_BULK_REGISTER_FAILED:
      return {
        ...state,
        isRegistering: false,
      }
    default:
      return state;
  }
}

// selectors
export function getDeviceList(state) {
  return state.device.devices;
}

export function getIsFetching(state) {
  return state.device.isFetching;
}

export function getIsDataGathering(state) {
  return state.device.isDataGathering;
}

export function getIsPublishing(state) {
  return state.device.isPublishing;
}

export function getIsPublishSucceed(state) {
  return state.device.isPublishSucceed;
}

export function getIsUpdating(state) {
  return state.device.isUpdating;
}

export function getIsRegistering(state) {
  return state.device.isRegistering;
}

export function getIsBulkUpdating(state) {
  return state.device.isBulkUpdating;
}

export function getIsBulkSucceed(state) {
  return state.device.isBulkSucceed;
}

export function getDeviceCount(state) {
  return state.device.deviceCountData;
}

export function getDeviceByMac(state, deviceMac) {
  return _.find(state.device.devices, d => d.deviceMac === deviceMac);
}

export function getDeviceById(state, deviceId) {
  return _.find(state.device.devices, d => d.id === deviceId);
}

export function getDeviceData(state) {
  return state.device.deviceData;
}

export function getPublishedMessage(state) {
  return state.device.publishedMessage;
}

export function getDeviceState(state, deviceId, type = null) {
  const deviceState = state.device.state ? state.device.state[deviceId] : undefined;

  if (!type || deviceState === undefined) {
    return deviceState;
  }
  return deviceState[type];
}

export function getDeviceListForSite(state, appSiteId) {
  return _.filter(state.device.devices, d => d && appSiteId && d.appSiteId && d.appSiteId === appSiteId);
}

function listToTree(list) {
  const map = {};
  const roots = [];
  _.forEach(list, (item, i) => {
    map[item.id] = i;
    list[i].children = []; // eslint-disable-line no-param-reassign
  });
  _.forEach(list, (item) => {
    if (item.parentId && _.has(map, item.parentId)) {
      list[map[item.parentId]].children.push(item);
    } else {
      roots.push(item);
    }
  });
  return roots;
}

export function getDeviceTree(state, appSiteId = null) {
  let devices = appSiteId ? getDeviceListForSite(state, appSiteId) : state.device.devices;
  // Ensure that we go through the devices without parents first
  devices = _.map(_.sortBy(devices, (d) => {
    if (!d.parentId) {
      return 0;
    } else if (d.deviceType === 'repeater') {
      return 1;
    }
    return 2;
  }), d => _.assign(_.pick(d, ['id', 'deviceType', 'deviceMac', 'parentId']), {
    name: d.deviceMac.slice(-5) + '.' + d.deviceType.slice(0,1).toUpperCase(),
    state: getDeviceState(state, d.id, 'dynamic'),
  }));
  const tree = listToTree(devices);
  return tree;
}
