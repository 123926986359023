import { call, put, takeEvery } from 'redux-saga/effects';
import processService from '../services/processes';
import * as processTypes from '../store/process/actionTypes';

export function* fetchProcesses(action) {
  try {
    const processes = yield call(processService.getProcesses, action.payload);
    yield put({ type: processTypes.APP_PROCESSES_SUCCEED, processes });
  } catch (e) {
    yield put({ type: processTypes.APP_PROCESSES_FAILED, message: e.message });
  }
}

export const processSaga = [
  takeEvery(processTypes.APP_PROCESSES_REQUESTED, fetchProcesses),
];
