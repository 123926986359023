import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import * as deviceSelectors from '../store/device/reducer';
import * as deviceTypes from '../store/device/actionTypes';
import * as processSelectors from '../store/process/reducer';
import * as processTypes from '../store/process/actionTypes';
import * as websocketSelectors from '../store/websocket/reducer';
import * as websocketTypes from '../store/websocket/actionTypes';

export function* webSocketUpdateDevices() {
  try {
    const devices = yield select(deviceSelectors.getDeviceList);
    const filter = {
      deviceIds: _.map(devices, 'id'),
    };
    yield put({ type: 'server/devices', filter, clear: true });
  } catch (e) {
    console.error('websocket devices client update failed.', e);
  }
}

// export function* webSocketUpdateAppSiteData() {
//   try {
//     const selectedSite = yield select(siteSelectors.getSelectedSite);
//     const devices = yield select(deviceSelectors.getDeviceList);
//     const filter = {
//       deviceMacs: _.map(devices, 'deviceMac'),
//       selectedSiteId: selectedSite.id,
//     };
//     yield put({ type: 'server/device-app-site-data', filter, clear: true });
//   } catch (e) {
//     console.error('websocket devices app site client update failed.', e);
//   }
// }

export function* webSocketUpdateDeviceData(action) {
  try {
    const device = yield select(deviceSelectors.getDeviceById, action.payload.deviceId);

    const filter = {
      device: _.pick(device, ['id', 'deviceType']),
    };
    switch (action.type) {
      case deviceTypes.DEVICES_DYNAMIC_DATA_FETCH_REQUESTED:
        filter.type = 'dynamic';
        break;
      case deviceTypes.DEVICE_TIMELINE_DATA_FETCH_REQUESTED:
        // we do not need to set type, since we want both types of data
        break;
      case deviceTypes.DEVICES_STATIC_DATA_FETCH_REQUESTED:
        filter.type = 'static';
        break;
      default:
        throw new Error('Invalid action to determine device data type');
    }
    yield put({ type: 'server/device-data', filter, clear: true });
  } catch (e) {
    console.error('websocket device data client update failed.', e);
  }
}

export function* webSocketUpdateDeviceCount() {
  try {
    yield put({ type: 'server/device-count', clear: true });
  } catch (e) {
    console.error('websocket device count client update failed.', e);
  }
}

export function* webSocketUpdateAppProcesses() {
  try {
    // We don't want to open a websocket per app, so instead we'll share
    // one websocket for all processes
    const processes = yield select(processSelectors.getProcesses);
    const filter = {
      processIds: _.map(processes, 'id'),
    };
    yield put({ type: 'server/app-process', filter, clear: true });
  } catch (e) {
    console.error('websocket app process client update failed.', e);
  }
}

export function* webSocketUpdateDeviceAlertData() {
  try {
    const devices = yield select(deviceSelectors.getDeviceList);
    const filter = {
      devices: _.map(devices, d => _.pick(d, ['id', 'deviceType'])),
    };
    yield put({ type: 'server/device-alerts', filter, clear: true });
  } catch (e) {
    console.error('websocket device alerts client update failed.', e);
  }
}

export function* webSocketResubscribe() {
  try {
    const subscriptions = yield select(websocketSelectors.getSubscriptions);
    yield all(subscriptions.map(s => call(function* sub() {
      const subscription = s.asMutable({ deep: true });
      const action = {
        type: subscription.topic,
        filter: subscription.filter,
        clear: true,
      };
      try {
        return yield put(action);
      } catch (e) {
        console.error('websocket resubscribe ind. failed.', e);
      }
    })));
  } catch (e) {
    console.error('websocket resubscribe failed.', e);
  }
}

export const webSocketSaga = [
  takeLatest([
    deviceTypes.DEVICES_FETCH_SUCCEED,
    deviceTypes.WEBSOCKET_DEVICE_FETCHED,
  ], webSocketUpdateDevices),
  // takeLatest([
  //   deviceTypes.DEVICES_FETCH_SUCCEED,
  //   deviceTypes.WEBSOCKET_DEVICE_FETCHED,
  // ], webSocketUpdateAppSiteData),
  takeLatest([
    deviceTypes.DEVICES_FETCH_SUCCEED,
    deviceTypes.WEBSOCKET_DEVICE_FETCHED,
  ], webSocketUpdateDeviceAlertData),
  takeLatest([
    deviceTypes.DEVICE_TIMELINE_DATA_FETCH_REQUESTED,
    deviceTypes.DEVICES_DYNAMIC_DATA_FETCH_REQUESTED,
  ], webSocketUpdateDeviceData),
  takeLatest(deviceTypes.DEVICES_COUNT_FETCH_SUCCEED, webSocketUpdateDeviceCount),
  takeLatest(processTypes.APP_PROCESSES_SUCCEED, webSocketUpdateAppProcesses),
  takeLatest(websocketTypes.WEBSOCKET_CONNECTED, webSocketResubscribe),
];
