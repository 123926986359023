import ServiceUtils from '../utils/ServiceUtils';

class CommentsService {
  async getLatestComments(token) {
    const queryString = ServiceUtils.getQueryString(token);
    const url = ServiceUtils.getFullURL(`/api/v1/auth/comments/latest${queryString}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`CommentService getLatestComments failed. Http status ${response.status}`);
    }

    const data = await response.json();
    return data;
  }

  async getCommentsForDevice(token) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices/${token.deviceId}/comments`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`CommentService getCommentsForDevice failed. Http status ${response.status}`);
    }

    const data = await response.json();
    return data;
  }

  async addComment(newComment) {
    const url = ServiceUtils.getFullURL('/api/v1/auth/comments');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newComment),
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`CommentService addComment failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }
}

export default new CommentsService();