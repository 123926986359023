import _ from 'lodash';

const ServiceUtils = {
  getQueryString: (params) => {
    const str = _.map(params, (val, key) => `${key}=${encodeURIComponent(val)}`);
    return `?${str.join('&')}`; 
  },

  getFullURL: (path) => {
    const clientConfig = window.client_config;
    if (_.isEmpty(clientConfig)) {
      return path;
    }
    return `${clientConfig.web_protocol}://${clientConfig.api_domain}:${clientConfig.api_port}${path}`;
  },
};

export default ServiceUtils;
