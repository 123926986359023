import { all } from 'redux-saga/effects';

import { userSaga } from './userSaga';
import { deviceSaga } from './deviceSaga';
import { processSaga } from './processSaga';
import { commentSaga } from './commentSaga';
import { siteSaga } from './siteSaga';
import { alertSaga } from './alertSaga';
import { webSocketSaga } from './webSocketSaga';
import { appSaga } from './appSaga';

export default function* rootSaga() {
  yield all([
    ...userSaga,
    ...deviceSaga,
    ...processSaga,
    ...commentSaga,
    ...siteSaga,
    ...alertSaga,
    ...webSocketSaga,
    ...appSaga,
  ]);
}

//Logger middleware used for debugging
export const logger = (store) => (next) => (action) => {
  next(action);
  console.log(action);
};

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  alert('Session has expired or unauthorized access, please login again.')
};
