/* global gapi */
import React, { useEffect } from 'react';

const GoogleSignIn = (props) => {
  useEffect(() => {
    initializeGoogleAPI();
  // eslint-disable-next-line
  }, [])

  const onGoogleSignIn = (googleUser) => {
    props.onSuccess(googleUser);
  }

  const initializeGoogleAPI = () => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = 'async';
    script.defer = 'defer';
    script.onload = () => {
      gapi.signin2.render('g-signin2', {
        scope: 'https://www.googleapis.com/auth/plus.login',
        width: 392,
        height: 50,
        theme: 'dark',
        longtitle: true,
        onsuccess: onGoogleSignIn,
      });
    };
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  }

  return (
    <div className="g-sign2" id="g-signin2" />
  );
}

export default GoogleSignIn;
