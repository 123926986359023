import _ from 'lodash';

import * as commentTypes from './actionTypes';

const initialState = {
  comments: [],
  isFetching: false,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case commentTypes.COMMENTS_FETCH_REQUESTED:
    case commentTypes.COMMENTS_FEED_FETCH_REQUESTED:
      return ({
        ...state,
        isFetching: true,
      });

    case commentTypes.COMMENTS_FETCH_SUCCEED:
    case commentTypes.COMMENTS_FEED_FETCH_SUCCEED: {
      const newComments = action.comments;
      const comments = _.map(state.comments, (comment) => {
        const index = _.findIndex(newComments, c => c.id === comment.id);
        if (index !== -1) {
          return newComments.splice(index, 1)[0];
        }
        return comment;
      });
      return ({
        ...state,
        comments: _.concat(comments, action.comments),
        isFetching: false,
      });
    }
    case commentTypes.COMMENTS_ADD_SUCCEED:
      return ({
        ...state,
        comments: _.concat([action.comment], state.comments),
        isFetching: false,
      });
    default:
      return state;
  }
}

export function getCommentsByDevice(state, deviceId) {
  return _.filter(state.comment.comments, c => c.deviceId === deviceId);
}

export function getCommentsByUser(state, userId) {
  return _.filter(state.comment.comments, c => c.userId === userId);
}

export function getLatestComments(state, count = 5) {
  const comments = _.sortBy(state.comment.comments, ['createdAt']).reverse();
  return _.slice(comments, 0, count);
}

export function getIsFetching(state) {
  return state.comment.isFetching;
}