import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as userSelectors from '../store/user/reducer';
import userService from '../services/users';
import * as deviceTypes from '../store/device/actionTypes';
import * as alertTypes from '../store/alert/actionTypes';
import * as userTypes from '../store/user/actionTypes';
import * as siteTypes from '../store/site/actionTypes';

import SagaUtils from '../utils/SagaUtils';

export function* loginGoogleUser(action) {
  try {
    const userData = yield call(userService.loginGoogle, action.payload);
    yield put({ type: userTypes.USER_UPDATED, userData });
  } catch (e) {
    yield put({ type: userTypes.USER_LOGIN_FAILED, message: e.message });
  }
}

export function* logoutGoogleUser() {
  try {
    yield call(userService.logoutGoogle);
    yield put({ type: userTypes.USER_UPDATED, userData: undefined });
  } catch (e) {
    yield put({ type: userTypes.USER_LOGOUT_FAILED, message: e.message });
  }
}

export function* fetchShortlist() {
  try {
    const user = yield select(userSelectors.getCurrentUser);
    const shortlistDevices = yield call(userService.getShortlist, user.id);
    const {
      shortlist,
      sites,
      alertConfigs,
      stateTree,
    } = SagaUtils.extractDeviceMeta(shortlistDevices);
    yield put({ type: alertTypes.ALERT_CONFIGS_FETCH_SUCCEED, alertConfigs });
    yield put({ type: siteTypes.SITES_FETCH_SUCCEED, sites });
    yield put({ type: deviceTypes.DEVICES_STATE_UPDATE, state: stateTree });
    yield put({ type: deviceTypes.DEVICES_FETCH_SUCCEED, devices: shortlistDevices });
    yield put({ type: userTypes.SHORTLIST_FETCH_SUCCEED, shortlist });
  } catch (e) {
    yield put({ type: userTypes.SHORTLIST_FETCH_FAILED, message: e.message });
  }
}

export function* addDeviceToShortlistForUser(action) {
  try {
    const user = yield select(userSelectors.getCurrentUser);
    const deviceId = action.payload.deviceId;
    yield call(userService.addDeviceToShortlistForUser, user.id, deviceId);
    yield put({ type: userTypes.USER_ADD_SHORTLIST_SUCCEED, payload: { deviceId } });
  } catch (e) {
    yield put({ type: userTypes.USER_ADD_SHORTLIST_FAILED, message: e.message });
  }
}

export function* removeDeviceFromShortlistForUser(action) {
  try {
    const user = yield select(userSelectors.getCurrentUser);
    const deviceId = action.payload.deviceId;
    yield call(userService.removeDeviceFromShortlistForUser, user.id, deviceId);
    yield put({ type: userTypes.USER_REMOVE_SHORTLIST_SUCCEED, payload: { deviceId } });
  } catch (e) {
    yield put({ type: userTypes.USER_REMOVE_SHORTLIST_FAILED, message: e.message });
  }
}

export const userSaga = [
  takeLatest(userTypes.USER_LOGOUT_GOOGLE_REQUESTED, logoutGoogleUser),
  takeLatest(userTypes.USER_LOGIN_GOOGLE_REQUESTED, loginGoogleUser),
  takeLatest(userTypes.SHORTLIST_FETCH_REQUESTED, fetchShortlist),
  takeLatest(userTypes.USER_ADD_SHORTLIST_REQUESTED, addDeviceToShortlistForUser),
  takeLatest(userTypes.USER_REMOVE_SHORTLIST_REQUESTED, removeDeviceFromShortlistForUser),
];
