import { call, put, takeLatest } from 'redux-saga/effects';
import siteService from '../services/sites';
import * as siteTypes from '../store/site/actionTypes';

export function* fetchSites(action) {
  try {
    const sites = yield call(siteService.fetchSites, action.payload);
    yield put({ type: siteTypes.SITES_FETCH_SUCCEED, sites });
  } catch (e) {
    yield put({ type: siteTypes.SITES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchSite(action) {
  try {
    const site = yield call(siteService.fetchSite, action.payload);
    yield put({ type: siteTypes.SITE_FETCH_SUCCEED, site });
  } catch (e) {
    yield put({ type: siteTypes.SITE_FETCH_FAILED, message: e.message });
  }
}

export const siteSaga = [
  takeLatest(siteTypes.SITES_FETCH_REQUESTED, fetchSites),
  takeLatest(siteTypes.SITE_FETCH_REQUESTED, fetchSite),
];
