import * as appTypes from './actionTypes';

const initialState = {
  isSending: false,
  sendingComplete: false,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case appTypes.APP_EMAILS_SEND_REQUESTED:
      return ({
        ...state,
        isSending: true,
        sendingComplete: false,
      });

    case appTypes.APP_EMAILS_SEND_SUCCEED:
      Object.assign(state, {
        isSending: false,
        sendingComplete: true,
      });
      return ({
        ...state,
        isSending: false,
        sendingComplete: true,
      });

    default:
      return state;
  }
}

export function getIsSending(state) {
  return state.app.isSending;
}

export function getSendingComplete(state) {
  return state.app.sendingComplete;
}