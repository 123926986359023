import _ from 'lodash';
import ServiceUtils from '../utils/ServiceUtils';

class DeviceService {
  async getDevices(token) {
    const queryString = ServiceUtils.getQueryString(token);
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices${queryString}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getDeviceList failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async getDeviceCount() {
    const url = ServiceUtils.getFullURL('/api/v1/auth/devices/actions/count');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getDeviceCount failed, HTTP status ${response.status}`);
    }

    const data = await response.json();
    return data;
  }

  async getDevice(deviceMac) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices/${deviceMac}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getDevice failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async updateDevice(device) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices/${device.deviceMac}`);
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(device),
      credentials: 'include',
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    const data = await response.json();
    return data;
  }

  async getDeviceData(type, token) {
    const queryString = ServiceUtils.getQueryString(_.omit(token, 'deviceId'));
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices/${token.deviceId}/data/${type}${queryString}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getDeviceData failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async getSiteDevices(site) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/sites/${site.id}/devices`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getSiteDevices failed, HTTP status ${response.status}`);
    }

    const data = await response.json();
    return data;
  }

  async publishToDevice(data) {
    const url = ServiceUtils.getFullURL('/api/v1/auth/devices/actions/publish');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    const message = await response.json();
    return message;
  }

  async bulkUpdateDevices(config) {
    const url = ServiceUtils.getFullURL('/api/v1/auth/devices/actions/bulk-update');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
      credentials: 'include',
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    return response.ok;
  }

  async getAlertConfigs(deviceId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices/${deviceId}/alert-configs`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getAlertConfigs failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async getState(type, deviceId) {
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices/${deviceId}/state/${type}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getState failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async getTimelineData(filter) {
    const queryString = ServiceUtils.getQueryString(filter);
    const url = ServiceUtils.getFullURL(`/api/v1/auth/devices/${filter.deviceId}/data/timeline${queryString}`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`DeviceService getTimelineData failed, HTTP status ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  async registerDevice(device) {
    const url = ServiceUtils.getFullURL('/api/v1/auth/devices/actions/register');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(device),
      credentials: 'include',
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    const data = await response.json();
    return data;
  }
}

export default new DeviceService();
