import { call, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import * as userSelectors from '../store/user/reducer';
import commentsService from '../services/comments';
import * as commentTypes from '../store/comment/actionTypes';

export function* fetchCommentsForDevice(action) {
  try {
    const comments = yield call(commentsService.getCommentsForDevice, action.payload);
    yield put({ type: commentTypes.COMMENTS_FETCH_SUCCEED, comments });
  } catch (e) {
    yield put({ type: commentTypes.COMMENTS_FETCH_FAILED, message: e.message });
  }
}

export function* fetchLatestComments(action) {
  try {
    const comments = yield call(commentsService.getLatestComments, action.payload);
    yield put({ type: commentTypes.COMMENTS_FEED_FETCH_SUCCEED, comments });
  } catch (e) {
    yield put({ type: commentTypes.COMMENTS_FEED_FETCH_FAILED, message: e.message });
  }
}

export function* addComment(action) {
  try {
    const user = yield select(userSelectors.getCurrentUser);
    _.assign(action.payload, { userId: user.id });
    const comment = yield call(commentsService.addComment, action.payload);
    comment.user = _.clone(user);
    comment.device = action.payload.device;
    yield put({ type: commentTypes.COMMENTS_ADD_SUCCEED, comment });
  } catch (e) {
    yield put({ type: commentTypes.COMMENTS_ADD_FAILED, message: e.message });
  }
}

export const commentSaga = [
  takeLatest(commentTypes.COMMENTS_FETCH_REQUESTED, fetchCommentsForDevice),
  takeLatest(commentTypes.COMMENTS_FEED_FETCH_REQUESTED, fetchLatestComments),
  takeLatest(commentTypes.COMMENTS_ADD_REQUESTED, addComment),
];
